body {
    background-image: url("images/MD_Grid_2.jpg");
}

.md-info-pages-container {
    color: white;
    background-color: black;
    font-family: 'Montserrat', sans-serif;
    font-size: 110%;
}

.map-iframe {
    display: block;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    /*max-width: 500px;*/
}

.md-credits-div {
    font-family: 'Montserrat SemiBold', sans-serif;
    list-style-type: none;
    padding-top: 1em;
}


.credit-entry {
    text-align: center;
}

.md-credits-div span {
    font-family: 'Montserrat', sans-serif;
    font-size: 75%;
    text-transform: uppercase;
}

.md-credits-list li {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}

.md-image-el {
    width: 100%;
    margin-bottom: 5%;
}

.md-image-el-hc {
    width: 100%;
}

.md-info-questions {
    height: 100%;
    display: flex;
    align-items: center;
}


.md-info-pages-text-div img {
    width: 100%;
    height: auto;
}

.link-to-app {
    width: 100%;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
}

a:link {
    color: rgb(0, 255, 251);
    font-family: 'Montserrat', sans-serif;
}
/**/
a:visited {
    color: rgb(185, 243, 255);
    font-family: 'Montserrat', sans-serif;
}

.md-info {
    padding-top: 4%;
    padding-bottom: 4%;
    text-align: left;
}

.md-info-left {
    font-style: italic;
}

.md-info-center {
    font-style: italic;
}

.md-info-right {
    font-style: italic;
}

.md-info-center-just {
    /*padding-left: 10%;*/
    /*padding-right: 10%;*/
    /*text-align: justify;*/
}

.md-info-just {
    text-align: justify;
}

.md-info-div {
    padding-top: 10px;
    padding-bottom: 10px;
}

.md-info-bump-extra {
    padding-bottom: 20px
}

.sample-images-row {
    width: 100%;
    display: flex;
    align-items: flex-start;
}

.sample-images-row img {
    height: 100%;
}

.sample-img {
    height: auto;
    width: 25%;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    display: block;
}

.sample-img-inactive {
    border-color: rgba(255, 255, 255, 0.1);
    filter: grayscale(100%);
    transition: 0.5s;
}

.sample-img-inactive:hover {
    filter: grayscale(0%);
    border-color: rgba(255, 255, 255, 0.25);
}

.sample-img-active {
    filter: grayscale(0%);
    border-color: rgba(255, 255, 255, 1.0);
}

/*.md-info-just div {*/
/*    text-align: justify;*/
/*}*/

.link-to-app-image {
    margin-right: 5%;
    height: 90%;
}

.md-banner-image-div {
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
}

.md-banner-image-div img {
    width: 100%;
}

footer {
}

img {
    /*width: 70%;*/
    /*display: block;*/
    /*margin-left: auto;*/
    /*margin-right: auto;*/
}

.md-slideshow-img {
    max-width: 100%;
    max-height: 100%;
    bottom: 0;
    left: 0;
    margin: auto;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 0;
    -o-object-fit: contain;
    object-fit: contain;
}

.md-trailer {
    margin-top: 2%;
    margin-bottom: 2%;
}

.md-trailer iframe {
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.footer-img {
    width: 20em;
    display:block;
    margin-left: auto;
    margin-right: auto;
}
