:root {
    --pack1-color: rgb(63, 193, 209);
    --pack1-color2: rgb(133, 255, 229);
    --pack1-faded: rgba(62, 193, 209, 0.35);

    --pack2-color: rgb(90, 90, 255);
    --pack2-color2: rgb(120, 120, 255);
    --pack2-faded: rgba(90, 90, 255, 0.35);

    --pack4-color: rgb(147, 93, 255);
    --pack4-color2: rgb(197, 143, 255);
    --pack4-faded: rgba(147, 93, 255, 0.35);

    --pack3-color: rgb(219,76,57);
    --pack3-color2: rgb(229, 96, 77);
    --pack3-faded: rgba(219,76,56,0.35);

    --fx-1-color: rgb(193, 49, 249);
    --fx-2-color: rgb(72, 49, 255);
    --fx-3-color: rgb(98, 49, 249);
    --fx-4-color: rgb(49, 223, 249);
}

*:focus {
    outline: none;
}

button:focus {
    outline: none;
}

html {
    background-color: black;
}

body {
    margin: 0;
    background-color: #000000;

    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    touch-action: manipulation;
    -ms-touch-action: manipulation;
}

.the-app {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    touch-action: manipulation;
    -ms-touch-action: manipulation;

}

#ref-pixi-div {
    width: 100%;
    height: 100%;
}

.fa-nav {
    font-size: 26px;
    color: #888888;
}

.fa-transport-icons {
    font-size: 24px;
    color: #888888;
    /*padding-left: 2px;*/
}

.fa-transport-icons-active {
    font-size: 24px;
    color: #ffffff;
}

.mui-transport-icons-active {
    font-size: 24px;
    color: #ffffff;
    margin-left: -3px;
}

.fa-transport-slider {
    height: 100%;
}



.fa-nav-enabled {
    font-size: 26px;
    color: #ffffff;
    opacity: 0.9;
}

.drumpad {
    height: 100%;
    margin: 5px 0 5px 0;
}

.drumpad-row {
    display: flex;
    width: 100%;
    background-color: black;
}

.drumpad-row-8 {
    height: 12.5%;
}

.drumpad-row-4 {
    height: 25%;
}

.drumpad-row-3 {
    height: 33.33333%;
}

.drumpad-row-12 {
    height: 8.33%;
}

.drumpad-button {
    /*margin: 5px 2px 5px 2px;*/
    background-color: black;
    height: 100%;
    border-radius: 0;
    border-color: black;
    border-style: solid;
    border-width: 2px;
    box-sizing: border-box;
}


.pack-theme-1 {
    background-image: radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 0) 5%, var(--pack1-color));
}

.pack-theme-1-outline {
    border-color: var(--pack1-color);
    /*background-image: radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 0), #3ec1d1);*/
}

.pack-theme-1-outline:active {
    border-color: #000000;
    border-width: 5px;
    /*background-color: #3ec1d1;*/
    background-clip: border-box;
    background-image: radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 0), var(--pack1-color));
}

.pack-theme-1:active {
    background-image: radial-gradient(circle at 50% 50%, var(--pack1-color2), var(--pack1-color));
}

.pack-theme-1-active {
    background-image: radial-gradient(circle at 50% 50%, var(--pack1-color2), var(--pack1-color));
}

.pack-theme-2 {
    background-image: radial-gradient(circle at 50% 50%, rgba(0,0,0,0) 5%, var(--pack2-color));
}

.pack-theme-2:active {
    background-image: radial-gradient(circle at 50% 50%, var(--pack2-color2), var(--pack2-color));
}

.pack-theme-2-active {
    background-image: radial-gradient(circle at 50% 50%, var(--pack2-color2), var(--pack2-color));
}

.pack-theme-3 {
    background-image: radial-gradient(circle at 50% 50%, rgba(0,0,0,0) 5%, var(--pack3-color));
}

.pack-theme-3:active {
    background-image: radial-gradient(circle at 50% 50%, var(--pack3-color2), var(--pack3-color));
}

.pack-theme-3-active {
    background-image: radial-gradient(circle at 50% 50%, var(--pack3-color2), var(--pack3-color));
}

.drumpad-button-8 {
    width: 12.5%;
}

.drumpad-button-4 {
    width: 25%;
}

.dot {
    margin-right: 2px;
    margin-left: 2px;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
}

.dot-inactive {
    background-color: #888888;
}

.dot-active {
    background-color: black;
}


.drumpad-button-3 {
    width: 33.333333%;
}

.drumpad-button-1 {
    width: 100%;
}

.md-sequencer {
    height: 100%;
    margin: 8px 0 8px 0;
    position: relative;
}

.md-sequencer canvas {
    position: absolute;
    width:100%;
    height: 100%;
}

.md-sequencer-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
}

.sequencer-row {
    display: flex;
    width: 100%;
    background-color: rgba(0,0,0,0);
}

.sequencer-row-4 {
    height: 25%;
}

.sequencer-row-3 {
    height: 33.333333%;
}

.sequencer-row-8 {
    height: 12.5%;
}

.sequencer-row-12 {
    height: 8.333333%;
}

.sequencer-row-16 {
    height: 6.25%;
}

.sequencer-button {
    box-sizing: border-box;
    border-style: solid;
    border-width: 1px;
    border-radius: 10%;
    background-color: rgba(0, 0, 0, 0);
    height: 100%;
    font-size: 0;
}

.sequencer-button-disabled {
    border-color: #555555;
}

.sequencer-button-disabled-active {
    border-color: black;
    background-color: #555555;
}

.sequencer-button-pack-0 {
    border-color: var(--pack1-faded);
}

.sequencer-button-pack-0-active {
    border-color: black;
    /*background-color: #3ec1d1;*/
    background-image: linear-gradient(var(--pack1-color), var(--pack1-color2));
    /*background-image: linear-gradient(105deg, #3ec1d1, #bc9a3e 100%);*/
}

.sequencer-button-pack-1 {
    border-color: var(--pack2-faded);
    /*border-color: rgb(95, 95, 95);*/
}

.sequencer-button-pack-1-active {
    border-color: black;
    background-image: linear-gradient(var(--pack2-color), var(--pack2-color2));
}

.sequencer-button-pack-2 {
    border-color: var(--pack3-faded);
}

.sequencer-button-pack-2-active {
    border-color: black;
    background-image: linear-gradient(var(--pack3-color), var(--pack3-color2));
    /*background-color: var(--pack3-color);*/
    /*background-image: linear-gradient(105deg, #db4c39, #bc9a3e);*/
}

.sequencer-button-active {
    background-image: linear-gradient(130deg, #935dff, #db742e 100%);
}

.sequencer-button-4 {
    width: 25%;
}

.sequencer-button-8 {
    width: 12.5%;
}

.clear-button-active {
    background-color: white !important;
    border-color: black !important;
}

.sequencer-button-16 {
    width: 6.25%;
}

.play-button-active {
    background-color: black !important;
    border-color: green !important;
}

.record-button-active {
    background-color: black !important;
    border-color: red !important;
}

.pack-select-1-active {
    background-color: var(--pack1-color) !important;
    border-color: black !important;
}

.pack-select-2-active {
    background-color: var(--pack2-color) !important;
    border-color: black;
}

.pack-select-3-active {
    background-color: var(--pack3-color) !important;
    border-color: black;
}

.md-mixer-div {
    margin: 20px;
    background-color: black;
    height: 90%;
}

.md-effects-ui-div {
    height: 100%;
    border-color: white;
    border-style: solid;
    border-width: 1px;
    margin-top: 6px;
    overflow: hidden;
}

.pack-select-button {
    background-color: #000;
    width: 33.3333333%;
    box-sizing: border-box;
    border-style: solid;
    border-width: 1px;
    border-image-slice: 1;
    margin: 0 2px 0 2px;
    height: 100%;
    border-radius: 10px;
}

.pack-select-inactive {
    border-color: #888888;
}

.settings-ui {
    height: 100%;
    position: relative;
    border-style: solid;
    border-width: 1px;
    border-color: #888888;
    border-radius: 10px;
    margin-top: 6px;
}

.settings-ui-div {

}

.effects-select-button {
    background-color: #000;
    width: 25%;
    box-sizing: border-box;;
    border-style: solid;
    border-width: 1px;
    border-image-slice: 1;
    margin: 0 2px 0 2px;
    height: 100%;
    border-radius: 10px;
}

.effects-select-inactive {
    border-color: #888888;
}

.effects-select-1 {
    background-color: var(--fx-1-color) !important;
    border-color: black !important;
}

.effects-select-2 {
    background-color: var(--fx-2-color) !important;
    border-color: black !important;
}

.effects-select-3 {
    background-color: var(--fx-3-color) !important;
    border-color: black !important;
}

.effects-select-4 {
    background-color: var(--fx-4-color) !important;
    border-color: black !important;
}

.submissionFormText {
    color: white;
}
